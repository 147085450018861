
import { defineComponent, computed } from 'vue'
import { useField } from 'vee-validate'
export default defineComponent({
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    testId: {
      type: String,
      default: 'checkbox'
    }
  },
  emits: ['update'],
  setup(props, { emit }) {
    const { value: formValue, handleChange } = useField<boolean>(props.name, undefined, {
      validateOnValueUpdate: false
    })
    const value = computed({
      get(): boolean {
        return formValue.value
      },
      set(value: boolean) {
        handleChange(value)
        emit('update', value)
      }
    })
    return { value }
  }
})
