
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const iconText = computed(() => {
      return props.text
        .match(/\b(\w)/g)
        ?.join('')
        .substr(0, 2)
    })
    return { iconText }
  }
})
