<template>
  <div class="radio">
    <slot name="radio-head" />
  </div>
</template>

<style lang="sass" scoped>
.radio
  width: 100%
  display: flex
  flex-direction: column
</style>
