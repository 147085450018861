<template>
  <div class="checbox-wrapper">
    <label class="checkbox">
      <input v-model="value" class="checkbox__input" type="checkbox" :data-testid="testId" />
      <span class="checkbox__in">
        <span class="checkbox__tick"></span>
        <span class="checkbox__text">{{ label }}</span>
      </span>
    </label>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useField } from 'vee-validate'
export default defineComponent({
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    testId: {
      type: String,
      default: 'checkbox'
    }
  },
  emits: ['update'],
  setup(props, { emit }) {
    const { value: formValue, handleChange } = useField<boolean>(props.name, undefined, {
      validateOnValueUpdate: false
    })
    const value = computed({
      get(): boolean {
        return formValue.value
      },
      set(value: boolean) {
        handleChange(value)
        emit('update', value)
      }
    })
    return { value }
  }
})
</script>
<style lang="sass" scoped>
.checbox-wrapper
  display: flex
.checkbox
  display: inline-block
  position: relative
  user-select: none
  cursor: pointer
  // padding: 0 1rem
  &__input
    position: absolute
    top: 0
    left: 0
    opacity: 0
  &__in
    display: flex
    align-items: center
  &__tick
    position: relative
    flex: 0 0 20px
    width: 20px
    height: 20px
    border-radius: 4px
    border: 1px solid map-get($greyShades, 'greyShade30')
    transition: all 0.25s
  &__tick:before
    content: ''
    position: absolute
    top: 54%
    left: 50%
    transform: translate(-50%, -50%)
    width: 18px
    height: 18px
    opacity: 0
    background: url("~@/assets/icons/checkbox-tick.svg") no-repeat 50% 50% / 100% auto
    transition: opacity 0.25s
  &__text
    margin-left: 8px
    font-size: 16px
    color: $primary
  &:hover &__tick
    border-color: $purple
  &__input:checked + &__in &__tick
    background: $purple
    border-color: $purple
  &__input:checked + &__in &__tick:before
    opacity: 1
  &__input:checked + &__in &__text
    color: $primary
</style>
