<template>
  <Header v-if="!isAdmin" headerClass="transparent"></Header>
  <div class="wrapper">
    <Form @submit="submit" :validation-schema="schema" :initial-values="initialValues.value" v-slot="{ values }">
      <FormWrapper>
        <template #form-head>
          <h2 v-if="isEdit === undefined">Add a new client</h2>
          <h2 v-if="isEdit !== undefined">Edit a client detail</h2>
        </template>
        <TextInput
          name="name"
          type="text"
          placeholderText="Client name"
          label="The client company name"
          testId="clientName"
        />
        <TextInput
          name="legalId"
          type="text"
          placeholderText="Client ID"
          label="The client company number"
          tooltipTitle="Client company number"
          tooltipText="A company registration number is a unique combination of numbers and, in some cases, letters. The company number is used to identify your company and verify the fact that it is a legally registered entity. You can find your (or a client’s or subcontractor’s) company number at your country’s registrar of companies."
          testId="clientId"
        />
        <TextInput
          name="address"
          type="text"
          placeholderText="Address"
          label="Their registered address"
          tooltipTitle="Their registered address"
          tooltipText="The official address of the client company registered with the registrar and can also be found on the company’s website."
          testId="address"
        />
        <SingleCheckBox
          label="This is different to their mailing address"
          name="mailingAddressDiff"
          testId="mailAddresscheckbox"
        />
        <TextInput
          v-if="values.mailingAddressDiff"
          type="text"
          name="mailingAdress"
          placeholderText="Address"
          label="Their mailing Address"
          testId="mailingaddress"
        />
        <SelectInput
          label="Account manager"
          selectLabel="Select"
          name="ownerId"
          :items="accountManagersItems"
          testId="ownerId"
        ></SelectInput>
        <TextInput
          type="text"
          name="leadContactName"
          placeholderText="Name"
          label="Who's the client contact?"
          testId="leadContactName"
        />
        <TextInput type="email" name="leadContactEmail" placeholderText="Email" testId="leadContactEmail" />
        <TextInput type="text" name="leadContactPhone" placeholderText="Phone" testId="leadContactPhone" />
        <TextInput
          type="text"
          name="financeContactName"
          placeholderText="Name"
          label="Finance team contact?"
          tooltipTitle="Finance team contact"
          tooltipText="The person who will receive your invoice."
          testId="financeContactName"
        />
        <TextInput type="email" name="financeContactEmail" placeholderText="Email" testId="financeContactEmail" />
        <TextInput type="text" name="financeContactPhone" placeholderText="Phone" testId="financecontactPhone" />
        <TextInput
          type="text"
          name="procurementContactName"
          placeholderText="Name"
          label="Procurement team contact?"
          tooltipTitle="Procurement team contact"
          tooltipText="The person who is directly responsible for sourcing and purchasing products and services for the client company."
          testId="procurementContactName"
        />
        <TextInput
          type="email"
          name="procurementContactEmail"
          placeholderText="Email"
          testId="procurementContactEmail"
        />
        <TextInput
          type="text"
          name="procurementContactPhone"
          placeholderText="Phone"
          testId="procurementContactPhone"
        />
        <template #footer>
          <CustomButton color="grey" :iconVisible="false" @onClick="$router.go(-1)" label="cancel" />
          <CustomButton
            type="submit"
            :label="isEdit ? 'Update client' : 'Set up client'"
            color="blue"
            testId="submitClient"
          />
        </template>
      </FormWrapper>
    </Form>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent, computed, onMounted } from 'vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import TextInput from '@/components/atom/TextInput.vue'
import SelectInput from '@/components/atom/SelectInput.vue'
import { Form } from 'vee-validate'
import Header from '@/components/organisms/Header.vue'
import FormWrapper from '@/components/molecules/Form.vue'
import SingleCheckBox from '@/components/atom/SingleCheckBox.vue'
import { ActionTypes as UserActionTypes } from '@/store/users/actions'
import { clientTypes } from '@shared/'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from '@/store'
import authApi from '@/api/Authenticated'
import * as yup from 'yup'

export default defineComponent({
  name: 'Client',
  components: {
    CustomButton,
    TextInput,
    SelectInput,
    Form,
    FormWrapper,
    SingleCheckBox,
    Header
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    const studioId = +route.params.studioId
    store.dispatch(UserActionTypes.GET_USERS, studioId)
    const isAdmin = computed(() => route.path.indexOf('super-admin') > -1)

    const accountManagersItems = computed(() => {
      return store.state.user.users.map((user) => {
        return { label: user.fullName, value: user.id }
      })
    })

    const isEdit = ref(route.params.id)

    const addinitialValues: clientTypes.ClientBody & { mailingAddressDiff: boolean } = {
      name: '',
      legalId: '',
      address: '',
      countryCode: 'UK',
      ownerId: 1,
      mailingAddressDiff: false,
      mailingAdress: '',
      leadContactName: '',
      leadContactEmail: '',
      leadContactPhone: '',
      financeContactName: '',
      financeContactEmail: '',
      financeContactPhone: '',
      procurementContactName: '',
      procurementContactEmail: '',
      procurementContactPhone: '',
      isArchived: false
    }

    const initialValues = ref({})
    async function getValues() {
      initialValues.value = isEdit.value
        ? ref<clientTypes.ClientResponse>(await authApi.getClientDetails(+route.params.id))
        : addinitialValues
    }
    onMounted(getValues)
    const phoneNumberRegex = /^(\(?\+?[0-9]{1,3}\)?)?([ .-]?)([0-9]{1,10})(([0-9]{2,5})|([() .-]?))*$/
    const schema = yup.object({
      ownerId: yup.string().required('Account manager is required'),
      name: yup.string().required().label('Name'),
      legalId: yup.string().required().label('Client id'),
      address: yup.string().required().label('Address'),
      countryCode: yup.string().required().label('Country code'),
      mailingAdress: yup.string(),
      leadContactName: yup.string().required().label('Name'),
      leadContactEmail: yup.string().required().email().label('Email'),
      leadContactPhone: yup.string().required().label('Phone').matches(phoneNumberRegex, 'Not a valid phone number'),
      financeContactEmail: yup.string().email().label('email'),
      financeContactPhone: yup.string().notRequired().label('phone').matches(phoneNumberRegex, {
        message: 'Not a valid phone number',
        excludeEmptyString: true
      }),
      procurementContactEmail: yup.string().email().label('Email'),
      procurementContactPhone: yup.string().notRequired().label('Phone').matches(phoneNumberRegex, {
        message: 'Not a valid phone number',
        excludeEmptyString: true
      })
    })

    async function submit(values: clientTypes.ClientBody & { mailingAddressDiff: boolean | undefined }) {
      const reqBody = { ...values, countryCode: 'UK' }
      delete reqBody.mailingAddressDiff
      const res = isEdit.value
        ? await authApi.updateClient(reqBody, +route.params.id)
        : await authApi.addNewClient(reqBody, studioId || undefined)
      if (res) {
        router.go(-1)
      }
    }

    return { accountManagersItems, initialValues, schema, submit, isEdit, isAdmin, studioId }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.wrapper
  padding-bottom: 90px
  display: flex
  width: 100%
  margin: 0 auto
  justify-content: center
</style>
