<template>
  <div class="radio-wrapper vertical">
    <div class="radio-main" v-for="item of radioButtonData" :key="item.id">
      <label class="radio"
        ><input v-model="value" class="radio__input" type="radio" :value="item.value" :data-testid="testId" /><span
          class="radio__in"
          ><span class="radio__tick"></span><span class="radio__text">{{ item.label }}</span></span
        ></label
      >
      <small class="hint-text" v-if="value == item.value">{{ item.hintText }}</small>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useField } from 'vee-validate'

export default defineComponent({
  name: 'CustomRadio',
  emits: ['update:modelValue'],
  props: {
    radioButtonData: {
      type: Array as () => { id: [string, number]; value: string; label: string; hintText: string }[],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    testId: {
      type: String
    }
  },
  setup(props, { emit }) {
    const { value: formValue, handleChange } = useField<string>(props.name, undefined, {
      validateOnValueUpdate: false
    })
    const value = computed({
      get(): string {
        return formValue.value
      },
      set(value: string) {
        handleChange(value)
      }
    })
    return { value }
  }
})
</script>
<style lang="sass" scoped>
.radio-wrapper
  display: flex
.radio-main
  width: 100%
.radio
  display: inline-block
  position: relative
  user-select: none
  cursor: pointer
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  padding: 1rem
  width: 100%
  &__input
    position: absolute
    top: 0
    left: 0
    opacity: 0
  &__in
    display: flex
  &__tick
    position: relative
    flex: 0 0 16px
    width: 16px
    height: 16px
    border-radius: 50%
    background: $white
    transition: all 0.25s
    border: 1px solid map-get($greyShades, 'greyShade30')
    &::before
      content: ''
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      width: 7px
      height: 7px
      border-radius: 50%
      background: $white
      opacity: 0
      transition: opacity 0.25s
      border-color: unset
  &__text
    margin-left: 8px
    font-size: 16px
    line-height: 1.2
    color: $primary
    transition: color 0.25s
  &__input:checked + &__in &__tick
    background: $purple
    border: unset
  &__input:checked + &__in &__tick:before
    opacity: 1
  &__input:checked + &__in &__text
.hint-text
  font-size: 12px
.vertical
  flex-direction: column
  justify-content: center
  label
    border: 1px solid
    background-color: $white
    height: 3.25rem
    border: 1px solid map-get($greyShades, 'greyShade20')
    margin: 3px 0
    span
      display: flex
      flex-direction: row-reverse
      justify-content: space-between
      align-items: center
</style>
