import { render } from "./CustomMultiSelectInput.vue?vue&type=template&id=2d7a3036&scoped=true"
import script from "./CustomMultiSelectInput.vue?vue&type=script&lang=ts"
export * from "./CustomMultiSelectInput.vue?vue&type=script&lang=ts"

import "@vueform/multiselect/themes/default.css?vue&type=style&index=0&lang=css"
import "./CustomMultiSelectInput.vue?vue&type=style&index=1&id=2d7a3036&scoped=true&lang=sass"
import "./CustomMultiSelectInput.vue?vue&type=style&index=2&id=2d7a3036&lang=sass"
script.render = render
script.__scopeId = "data-v-2d7a3036"

export default script