<template>
  <div class="datepicker-wrapper">
    <span class="datapicker-label">{{ label }}</span>

    <div class="datepicker" :class="!border ? 'border-less' : ''">
      <datepicker
        :lowerLimit="minDate"
        :upperLimit="maxDate"
        :disabled="disabled"
        v-model="value"
        placeholder="Select date"
        inputFormat="dd/MM/yyyy"
        :data-testid="testId"
      />

      <div class="date-icon"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

import Datepicker from 'vue3-datepicker'

export default defineComponent({
  emits: ['update:modelValue'],

  components: {
    Datepicker
  },

  props: {
    label: {
      type: String
    },

    modelValue: {
      type: Date
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    },
    testId: {
      type: String,
      default: 'datePickerModal'
    }
  },

  setup(props, { emit }) {
    const value = computed({
      get(): any {
        return props.modelValue ? new Date(props.modelValue) : null
      },

      set(newValue: Date) {
        emit('update:modelValue', newValue)
      }
    })

    return { value }
  }
})
</script>
<style lang="sass">
.datepicker-wrapper
  display: flex
  flex-direction: column
  width: 100%
.datepicker
  position: relative
  cursor: pointer
.datapicker-label
  text-align: left
.v3dp__datepicker
  input
    width: 100%
    padding: 1rem
    padding-left: 2.5rem
    cursor: pointer
    border: solid 1px map-get($greyShades, 'greyShade20')
    border-radius: 2px
    font-size: 16px
    position: relative
    font-family: inherit
    &:focus
      outline: none
.border-less
  .v3dp__datepicker
    input
      border: none
      padding: 0 2.5rem
.date-icon
  background-repeat: no-repeat
  background-image: url('~@/assets/icons/calendar.svg')
  background-size: contain
  background-position: center
  position: absolute
  height: 1.5rem
  width: 1.5rem
  top: 14px
  left: 5px
  pointer-events: none
.border-less
  .date-icon
    top: 0
</style>
