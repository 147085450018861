<template>
  <Header v-if="!isAdmin" headerClass="transparent"></Header>
  <div class="wrapper">
    <Form @submit="submit" :validation-schema="schema" :initial-values="initialValues">
      <FormWrapper>
        <template #form-head>
          <h2>Project details</h2>
        </template>
        <h3>Project details</h3>
        <TextInput
          name="name"
          type="text"
          placeholderText="Project name"
          label="Project name"
          descText="What is the project called ?"
          testId="name"
        />
        <SelectInput
          label="Client name"
          descText="Which client is this project for? If you are agency, please select the relevant Studiospace entity for your region."
          selectLabel="Select"
          name="clientId"
          :items="clients"
          testId="clientId"
        ></SelectInput>
        <div class="mb-2">
          <CustomButton @click="$router.push({ name: 'AddClient' })" label="Add client" />
        </div>
        <TextInput
          descText="This is the person who will receive all relevant project admin, such as Statement of Work and Invoices. If you are agency, this should be your Studiospace contact"
          label="Project lead name"
          name="projectLeadName"
          type="text"
          placeholderText="Lead name"
          testId="projectLeadName"
        />
        <TextInput
          descText="This is the person who will receive all relevant project admin, such as Statement of Work and Invoices. If you are agency, this should be your Studiospace contact"
          label="Project lead email"
          name="projectLeadEmail"
          type="email"
          placeholderText="Lead email"
          testId="projectLeadEmail"
        />
        <TextInput name="phase" type="text" placeholderText="Project Phase" label="Phase" testId="phase" />
        <Datepicker name="startDate" label="Start date" testId="startDate"></Datepicker>
        <TextInput type="number" name="numberOfWeeks" placeholderText="weeks" label="Duration" testId="numberOfWeeks" />
        <SelectInput
          label="Project manager"
          selectLabel="Select"
          name="ownerId"
          :items="projectManagersItems"
          testId="ownerId"
        ></SelectInput>

        <span><b>Custom Fields</b></span>

        <div v-for="(item, index) of detailsCustomFields" :key="item.id" class="custom-fields">
          <div>
            <div>
              <CustomInputModel
                placeholderText="Enter name"
                v-model="item.heading"
                inputViewType="small"
                testId="staffingname"
                label="Heading"
              ></CustomInputModel>
            </div>
            <div>
              <CustomTextArea
                placeholderText="Description"
                rows="4"
                v-model="item.description"
                name="description"
                testId="description"
                label="Description"
              />
            </div>
          </div>
          <a class="cross-button" @click="() => removeCustomField(index)">X</a>
        </div>
        <CustomButton
          class="half-button"
          @onClick="addCustomField('ProjectDetails')"
          iconClass="plus"
          label="Add custom field"
          color="grey"
          size="small"
          testId="addCustomField"
        />

        <h3>Scope &amp; deliverables</h3>

        <TextArea
          name="overview"
          placeholderText="Give some context as to what this document is about."
          label="Overview"
          rows="5"
          testId="overview"
        />

        <TextArea
          name="background"
          placeholderText="Write a short introduction to the project here, describing the client’s situation and providing a summary of the challenge."
          label="Project background"
          rows="5"
          testId="projectBackground"
        />
        <TextArea
          name="services"
          placeholderText="Write a short summary of the services that the agency will provide (e.g. we will deliver this project through a combination of research and insight methods involving prototyping and customer testing)."
          label="Services"
          rows="5"
          testId="services"
        />
        <TextArea
          name="approach"
          placeholderText="Write a short summary of the approach that the agency will provide (e.g. we will follow this approach through a combination of popular techniques and market evaluated standards)."
          label="Approach"
          rows="5"
          testId="approach"
        />
        <span>Deliverables</span>
        <div v-for="(item, index) of mileStonesArray" :key="item.id" class="loop-wrapper">
          <div class="loop-card flexy-wrap">
            <div>
              <CustomInputModel
                placeholderText="Enter deliverable"
                v-model="item.name"
                inputViewType="small"
                testId="enterDeliverable"
              ></CustomInputModel>
              <a class="cross-button" @click="() => removeMilestone(index)">X</a>
            </div>
            <div>
              <DatePickerModelValue v-model="item.date" testId="deliverableMilestone"></DatePickerModelValue>
            </div>
            <div class="clearfix"></div>
            <div class="full-width">
              <CustomTextArea
                placeholderText="Description"
                rows="4"
                v-model="item.description"
                name="description"
                testId="description"
              />
            </div>
          </div>
        </div>
        <CustomButton
          class="half-button"
          @onClick="addMilestone"
          iconClass="plus"
          label="Add deliverable"
          color="grey"
          size="small"
          testId="addDeliverable"
        />

        <span>Staffing</span>
        <div v-for="(item, index) of staffingArray" :key="item.id" class="loop-wrapper">
          <div class="loop-card flexy-wrap three-col-wrap">
            <div>
              <CustomInputModel
                placeholderText="Enter name"
                v-model="item.name"
                inputViewType="small"
                testId="staffingname"
              ></CustomInputModel>
              <a class="cross-button" @click="() => removeStaffing(index)">X</a>
            </div>
            <div>
              <CustomInputModel
                placeholderText="Enter role"
                v-model="item.role"
                inputViewType="small"
                testId="staffingrole"
              ></CustomInputModel>
            </div>
          </div>
        </div>
        <CustomButton
          class="half-button"
          @onClick="addStaffing"
          iconClass="plus"
          label="Add more"
          color="grey"
          size="small"
          type="button"
          testId="addSTaffing"
        />
        <TextArea
          name="restrictions"
          placeholderText="Add any specific project terms, requirements or assumptions here"
          label="Restrictions or dependencies requirements"
          rows="5"
          testId="restrictions"
        />
        <span><b>Custom Fields</b></span>

        <div v-for="(item, index) of scopeCustomFields" :key="item.id" class="custom-fields">
          <div>
            <div>
              <CustomInputModel
                placeholderText="Enter name"
                v-model="item.heading"
                inputViewType="small"
                testId="staffingname"
                label="Heading"
              ></CustomInputModel>
            </div>
            <div>
              <CustomTextArea
                placeholderText="Description"
                rows="4"
                v-model="item.description"
                name="description"
                testId="description"
                label="Description"
              />
            </div>
          </div>
          <a class="cross-button" @click="() => removeCustomField(index)">X</a>
        </div>
        <CustomButton
          class="half-button"
          @onClick="addCustomField('ScopeDetails')"
          iconClass="plus"
          label="Add custom field"
          color="grey"
          size="small"
          testId="addCustomField"
        />
        <h3>Commercial details</h3>
        <SelectInput
          hintText="What is the total value of the project (excluding VAT / GST) and how often is it invoiced?"
          selectLabel="Select"
          label="Fees & charges"
          value="Select"
          :items="feeStructureTypes"
          name="type"
          testId="feeStructure"
        />
        <TextInput
          label="Total value"
          type="number"
          name="totalValue"
          placeholderText="Total Value"
          testId="totalValue"
        />
        <TextInput
          label="Purchase order number"
          type="text"
          name="purchaseOrder"
          placeholderText="PO no."
          testId="purchaseOrder"
        />
        <div class="invoice-wrapper">
          <span>Invoice schedule</span>
          <div v-for="(invoiceData, index) of invoiceArray" :key="invoiceData.date" class="invoice-section">
            <DatePickerModelValue
              :disabled="invoiceData.id ? true : false"
              v-model="invoiceData.date"
              testId="invoiceDate"
            ></DatePickerModelValue>
            <CustomInputModel
              placeholderText="Enter invoice value"
              inputViewType="small"
              v-model="invoiceData.amount"
              :disabled="invoiceData.id ? true : false"
              testId="invoiceValue"
            ></CustomInputModel>
            <a
              v-if="invoiceData.status !== 'Sent' && invoiceData.status !== 'Paid' && invoiceData.status !== 'Archived'"
              class="cross-button"
              data-testid="removeInvoice"
              @click="() => removeInvoice(index)"
              >X</a
            >
          </div>
          <CustomButton
            class="half-button"
            @onClick="addInvoice"
            iconClass="plus"
            label="Add invoice date"
            color="grey"
            size="small"
            type="button"
            testId="addInvoice"
          />
        </div>
        <TextInput type="number" name="vatPercentage" label="VAT/GST" placeholderText="%" testId="vatPercentage" />
        <TextInput
          type="number"
          name="paymentTermsDays"
          label="Payment terms"
          placeholderText="Days"
          testId="paymentTerms"
        />
        <SingleCheckBox :label="'Should expenses be included?'" :name="'expensesIncluded'" testId="expenseCheckbox" />

        <span><b>Custom Fields</b></span>

        <div v-for="(item, index) of commercialCustomFields" :key="item.id" class="custom-fields">
          <div>
            <div>
              <CustomInputModel
                placeholderText="Enter name"
                v-model="item.heading"
                inputViewType="small"
                testId="staffingname"
                label="Heading"
              ></CustomInputModel>
            </div>
            <div>
              <CustomTextArea
                placeholderText="Description"
                rows="4"
                v-model="item.description"
                name="description"
                testId="description"
                label="Description"
              />
            </div>
          </div>
          <a class="cross-button" @click="() => removeCustomField(index)">X</a>
        </div>
        <CustomButton
          class="half-button"
          @onClick="addCustomField('CommercialDetails')"
          iconClass="plus"
          label="Add custom field"
          color="grey"
          size="small"
          testId="addCustomField"
        />

        <h3>Legal</h3>
        <TextArea
          name="contract"
          placeholderText="If the project has any contract management provisions - monthly meeting etc., detail them here"
          label="Contract management"
          rows="5"
          testId="contractManagement"
        />
        <TextArea
          name="additional"
          placeholderText="If there are any additional items, detail them here. If the project has a required location detail it here."
          label="Special requests or additional terms"
          rows="5"
          testId="additionalTerms"
        />
        <!-- Additional Info Ends -->
        <template #footer>
          <CustomButton
            color="grey"
            :iconVisible="false"
            @onClick="$router.go(-1)"
            label="Cancel"
            testId="cancelEdit"
          />
          <CustomButton
            type="submit"
            :label="projectId ? 'Update project' : 'Add project'"
            color="blue"
            testId="updateProject"
          />
        </template>
      </FormWrapper>
    </Form>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import TextInput from '@/components/atom/TextInput.vue'
import { Form } from 'vee-validate'
import Header from '@/components/organisms/Header.vue'
import FormWrapper from '@/components/molecules/Form.vue'
import Datepicker from '@/components/atom/DatePicker.vue'
import SelectInput from '@/components/atom/SelectInput.vue'
import TextArea from '@/components/atom/TextArea.vue'
import SingleCheckBox from '@/components/atom/SingleCheckBox.vue'
import { projectTypes } from '@shared/'
import { useRouter, useRoute } from 'vue-router'
import authApi from '@/api/Authenticated'
import * as yup from 'yup'
import { useStore } from '@/store'
import { ActionTypes as ClientActionTypes } from '@/store/clients/actions'
import { ActionTypes as UserActionTypes } from '@/store/users/actions'
import CustomInputModel from '@/components/atom/CustomInputModel.vue'
import DatePickerModelValue from '@/components/atom/DatePickerModelValue.vue'
import { currencyToInt, inputFieldFormat } from '@/utils/currencyFunctions'
import { decimalRound, decimalValueTest } from '@/utils/commonFunctions'
import moment from 'moment'
import CustomTextArea from '@/components/atom/CustomTextArea.vue'

export default defineComponent({
  name: 'AddProject',
  components: {
    CustomButton,
    TextInput,
    Form,
    FormWrapper,
    SelectInput,
    SingleCheckBox,
    Header,
    Datepicker,
    TextArea,
    CustomInputModel,
    DatePickerModelValue,
    CustomTextArea
  },
  async setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const isAdmin = computed(() => route.path.indexOf('super-admin') > -1)
    const studioId = +route.params.studioId
    const studioDetails = computed(() => store.state.studio.studioDetails)
    const projectId = +route.params.id

    store.dispatch(ClientActionTypes.GET_CLIENTS, studioId)
    store.dispatch(UserActionTypes.GET_USERS, studioId)
    const currentValues = projectId ? await authApi.getProjectDetails(projectId) : null

    if (currentValues && currentValues.milestones.length === 0) {
      currentValues.milestones.push({ name: '', description: '', date: new Date() })
    }

    const clients = computed(() => {
      return store.state.client.clients.map((client) => {
        return { label: client.name, value: client.id }
      })
    })
    const projectManagersItems = computed(() => {
      return store.state.user.users.map((user) => {
        return { label: user.fullName, value: user.id }
      })
    })
    const feeStructureTypes = [
      {
        id: 1,
        label: 'Fixed fee',
        value: 'FixedPrice'
      },
      {
        id: 2,
        label: 'Bill per hour',
        value: 'BillPerHour'
      }
    ]

    const valuesFromApi: projectTypes.ProjectBody = {
      ...currentValues,
      totalValue: currentValues && currentValues.totalValue ? inputFieldFormat(currentValues.totalValue) : null,
      startDate: currentValues ? new Date(currentValues.startDate) : new Date(),
      invoices: currentValues
        ? currentValues.invoices.map((item: any) => {
            return { ...item, date: new Date(item.date) }
          })
        : []
    }

    const initialValues = Object.assign({}, valuesFromApi)
    const mileStonesArray = ref(initialValues.milestones ? [...initialValues.milestones] : [])
    initialValues.vatPercentage = valuesFromApi.vatPercentage
      ? valuesFromApi.vatPercentage
      : studioDetails.value
      ? studioDetails.value.VatPercentage
      : 0
    const invoiceArray = ref(
      initialValues.invoices.map((e: any) => {
        const totalAmount = e.invoiceItems
          .map((item: any) => item.unitsCount * item.costPerUnit)
          .reduce((a: any, b: any) => a + b, 0)
        return {
          ...e,
          amount: inputFieldFormat(totalAmount)
        }
      })
    )

    const staffingArray = ref(
      initialValues.staffing && initialValues.staffing.length > 0
        ? [...initialValues.staffing]
        : [{ name: '', role: '' }]
    )
    const customFields = ref(currentValues ? currentValues.projectCustomFields : [])
    const detailsCustomFields = computed(() => customFields.value.filter((e: any) => e.type === 'ProjectDetails'))
    const scopeCustomFields = computed(() => customFields.value.filter((e: any) => e.type === 'ScopeDetails'))
    const commercialCustomFields = computed(() => customFields.value.filter((e: any) => e.type === 'CommercialDetails'))

    const schema = yup.object({
      clientId: yup.number().required().label('Client'),
      name: yup.string().required().label('Name'),
      projectLeadName: yup.string().required().label('Project lead name'),
      projectLeadEmail: yup.string().required().email().label('Project lead email'),
      phase: yup.string().nullable(),
      startDate: yup.string().required(),
      ownerId: yup.number().required(),
      numberOfWeeks: yup.number().typeError('Duration must be written as a whole number').min(0).required(),
      overview: yup.string().nullable(),
      background: yup.string().nullable(),
      services: yup.string().nullable(),
      approach: yup.string().nullable(),
      restrictions: yup.string().nullable(),
      contract: yup.string().nullable(),
      additional: yup.string().nullable(),
      type: yup.string().nullable(),
      purchaseOrder: yup.string().nullable(),
      vatPercentage: yup.number().typeError('VAT/GST must be written as a whole number'),
      paymentTermsDays: yup
        .number()
        .test('is-decimal', 'Payment terms days must be written as a whole number', (val) => {
          if (val !== undefined) {
            return decimalValueTest(val.toString())
          }
          return true
        })
        .typeError('Payment terms days must be written as a whole number'),
      expensesIncluded: yup.boolean().nullable(),
      totalValue: yup.number().typeError('Total value must be written as a whole number Or as a decimal').nullable(),
      milestones: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().required().label('Name')
          })
        )
        .strict(),
      invoices: yup.array().of(
        yup.object().shape({
          date: yup.date().required().label('date')
        })
      )
    })

    const addMilestone = () => {
      mileStonesArray.value.push({ name: '', description: '', date: null })
    }

    const removeMilestone = (index: number) => {
      const filteredArr = mileStonesArray.value.filter((element, i: number) => {
        return i !== index
      })
      mileStonesArray.value = [...filteredArr]
    }

    const addStaffing = () => {
      staffingArray.value.push({ name: '', role: '' })
    }

    const removeStaffing = (index: number) => {
      const filteredArr = staffingArray.value.filter((element, i: number) => {
        return i !== index
      })
      staffingArray.value = [...filteredArr]
    }

    const addInvoice = () => {
      invoiceArray.value.push({ date: new Date(new Date().setHours(0, 0, 0, 0)), amount: 0 })
    }

    const removeInvoice = (index: number) => {
      invoiceArray.value.splice(index, 1)
    }

    const addCustomField = (type: 'ProjectDetails' | 'ScopeDetails' | 'CommercialDetails') => {
      customFields.value.push({ heading: '', description: '', type: type })
    }

    const removeCustomField = (index: number) => {
      customFields.value.splice(index, 1)
    }
    async function submit(values: projectTypes.ProjectBody) {
      values.milestones = mileStonesArray.value
        .map((e: any) => {
          return {
            ...e,
            date: moment(e.date).format('L')
          }
        })
        .filter((e) => e.name !== '')

      values.invoices = invoiceArray.value.map((e: any) => {
        return {
          ...e,
          date: moment(e.date).format('L')
        }
      })
      values.projectCustomFields = customFields.value
      values.staffing = staffingArray.value.filter((e) => e.name !== '' && e.role !== '')
      values.vatPercentage = Number(values.vatPercentage)
      values.paymentTermsDays = Number(values.paymentTermsDays)
      values.totalValue = values.totalValue ? currencyToInt(decimalRound(values.totalValue)) : null
      values.numberOfWeeks = Number(values.numberOfWeeks)
      values.startDate = moment(values.startDate).format('L')
      if (values.invoices) {
        values.invoices = values.invoices.map((item: any) => {
          return { ...item, amount: currencyToInt(+item.amount) }
        })
      }
      if (!projectId) {
        values.numberOfWeeks = Number(values.numberOfWeeks)
        values.vatPercentage = values.vatPercentage
          ? values.vatPercentage
          : studioDetails.value?.currencyCode === 'AUD'
          ? 10
          : 20
        values.startDate = moment(values.startDate).format('L')
        await authApi.addNewProject(values, studioId || undefined).then(() => {
          router.go(-1)
        })
      } else {
        await authApi.updateProject(values, +projectId).then(() => {
          router.go(-1)
        })
      }
    }

    return {
      submit,
      initialValues,
      addMilestone,
      removeMilestone,
      addInvoice,
      removeInvoice,
      schema,
      feeStructureTypes,
      clients,
      projectManagersItems,
      mileStonesArray,
      invoiceArray,
      isAdmin,
      staffingArray,
      addStaffing,
      removeStaffing,
      projectId,
      detailsCustomFields,
      addCustomField,
      removeCustomField,
      scopeCustomFields,
      commercialCustomFields
    }
  }
})
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
@import '~@/sass/flexy-wrap.sass'
.wrapper
  padding-bottom: 90px
  display: flex
  width: 100%
  margin: 0 auto
  justify-content: center
.half-button
  width: 50%
  margin-top: 1rem
.invoice-section
  display: flex
  align-items: flex-start
  justify-content: center
  position: relative
  & > *
    flex: 1
    margin-bottom: 5px
    &:first-child
      margin-right: 0.5rem
.loop-wrapper
  position: relative
small
  font-size: 12px
.additional-info > *
  margin: 1em 0
.cross-button
  width: 2rem
  height: 2rem
  border: 1px solid $white
  display: block
  position: absolute
  right: -40px
  top: 10px
  border-radius: 100%
  text-align: center
  background-color: $white
  cursor: pointer
  box-shadow: 0px 4px 13px rgba(14, 30, 54, 0.1)
  display: flex
  align-items: center
  justify-content: center
.hidden-field
  display: none
.flexy-wrap textarea
  resize: vertical
.wrapper div.mb-2
  margin-bottom: 2em
.custom-fields
  border: 1px solid map-get($greyShades, 'greyShade20')
  position: relative
  padding: 1rem
  background-color: #fff
</style>
