
import { ref, defineComponent, computed, onMounted } from 'vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import TextInput from '@/components/atom/TextInput.vue'
import SelectInput from '@/components/atom/SelectInput.vue'
import { Form } from 'vee-validate'
import Header from '@/components/organisms/Header.vue'
import FormWrapper from '@/components/molecules/Form.vue'
import SingleCheckBox from '@/components/atom/SingleCheckBox.vue'
import { ActionTypes as UserActionTypes } from '@/store/users/actions'
import { clientTypes } from '@shared/'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from '@/store'
import authApi from '@/api/Authenticated'
import * as yup from 'yup'

export default defineComponent({
  name: 'Client',
  components: {
    CustomButton,
    TextInput,
    SelectInput,
    Form,
    FormWrapper,
    SingleCheckBox,
    Header
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    const studioId = +route.params.studioId
    store.dispatch(UserActionTypes.GET_USERS, studioId)
    const isAdmin = computed(() => route.path.indexOf('super-admin') > -1)

    const accountManagersItems = computed(() => {
      return store.state.user.users.map((user) => {
        return { label: user.fullName, value: user.id }
      })
    })

    const isEdit = ref(route.params.id)

    const addinitialValues: clientTypes.ClientBody & { mailingAddressDiff: boolean } = {
      name: '',
      legalId: '',
      address: '',
      countryCode: 'UK',
      ownerId: 1,
      mailingAddressDiff: false,
      mailingAdress: '',
      leadContactName: '',
      leadContactEmail: '',
      leadContactPhone: '',
      financeContactName: '',
      financeContactEmail: '',
      financeContactPhone: '',
      procurementContactName: '',
      procurementContactEmail: '',
      procurementContactPhone: '',
      isArchived: false
    }

    const initialValues = ref({})
    async function getValues() {
      initialValues.value = isEdit.value
        ? ref<clientTypes.ClientResponse>(await authApi.getClientDetails(+route.params.id))
        : addinitialValues
    }
    onMounted(getValues)
    const phoneNumberRegex = /^(\(?\+?[0-9]{1,3}\)?)?([ .-]?)([0-9]{1,10})(([0-9]{2,5})|([() .-]?))*$/
    const schema = yup.object({
      ownerId: yup.string().required('Account manager is required'),
      name: yup.string().required().label('Name'),
      legalId: yup.string().required().label('Client id'),
      address: yup.string().required().label('Address'),
      countryCode: yup.string().required().label('Country code'),
      mailingAdress: yup.string(),
      leadContactName: yup.string().required().label('Name'),
      leadContactEmail: yup.string().required().email().label('Email'),
      leadContactPhone: yup.string().required().label('Phone').matches(phoneNumberRegex, 'Not a valid phone number'),
      financeContactEmail: yup.string().email().label('email'),
      financeContactPhone: yup.string().notRequired().label('phone').matches(phoneNumberRegex, {
        message: 'Not a valid phone number',
        excludeEmptyString: true
      }),
      procurementContactEmail: yup.string().email().label('Email'),
      procurementContactPhone: yup.string().notRequired().label('Phone').matches(phoneNumberRegex, {
        message: 'Not a valid phone number',
        excludeEmptyString: true
      })
    })

    async function submit(values: clientTypes.ClientBody & { mailingAddressDiff: boolean | undefined }) {
      const reqBody = { ...values, countryCode: 'UK' }
      delete reqBody.mailingAddressDiff
      const res = isEdit.value
        ? await authApi.updateClient(reqBody, +route.params.id)
        : await authApi.addNewClient(reqBody, studioId || undefined)
      if (res) {
        router.go(-1)
      }
    }

    return { accountManagersItems, initialValues, schema, submit, isEdit, isAdmin, studioId }
  }
})
