<template>
  <div class="form-group">
    <span v-if="label" class="input-label">{{ label }}</span>
    <div class="input-field">
      <textarea
        :name="name"
        :id="name"
        v-model="inputValue"
        :placeholder="placeholderText"
        @input="(event) => $emit('update:modelValue', inputValue)"
        :rows="rows"
        :data-testid="testId"
      >
      </textarea>
      <!--  <span v-if="errorMessage" class="input-error">{{ errorMessage }}</span> -->
    </div>
    <small class="hint-text" v-if="hint">{{ hint }}</small>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  emits: ['update:modelValue'],
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholderText: String,
    name: {
      type: [String, Number],
      required: false
    },
    label: {
      type: String
    },
    rows: {
      type: String
    },
    modelValue: {
      type: [String, Number]
    },
    required: {
      type: Boolean,
      default: false
    },
    testId: {
      type: String
    },
    hint: String
  },
  setup(props) {
    const inputValue = props.modelValue ? ref(props.modelValue) : null
    return {
      inputValue
    }
  }
}
</script>

<style lang="sass" scoped>
.form-group
  position: relative
  width: 100%
  display: flex
  flex-direction: column
.input-field
  position: relative
textarea
  font-size: 1rem
  font-family: TT-Norms-Pro
  padding: 1rem 0.5rem
  display: block
  width: 100%
  border: solid 1px map-get($greyShades, 'greyShade20')
  border-radius: 2px
  caret-color: $primary
  color: $primary
  transition: 0.2s ease all
  -moz-transition: 0.2s ease all
  -webkit-transition: 0.2s ease all
  text-overflow: ellipsis
  &:focus
    outline: none
    border: solid 1px map-get($greyShades, 'greyShade70')
    &:disabled
      border: solid 1px map-get($greyShades, 'greyShade20')
    &:disabled ~ label
      color: map-get($greyShades, 'greyShade50')

label
  color: $primary
  font-size: 14px
  font-family: TT-Norms-Pro
  font-weight: normal
  position: absolute
  pointer-events: none
  right: 1rem
  line-height: 1rem
  top: 20px
  display: flex
  align-items: center
  transition: 0.2s ease all
  -moz-transition: 0.2s ease all
  -webkit-transition: 0.2s ease all
.hint-text
  color: $primary
  font-family: TT-Norms-Pro
  font-size: 12px
.input-label
  text-align: left
  margin-bottom: 4px
  margin-top: 1rem
</style>
