
import { defineComponent, computed } from 'vue'

import Datepicker from 'vue3-datepicker'

export default defineComponent({
  emits: ['update:modelValue'],

  components: {
    Datepicker
  },

  props: {
    label: {
      type: String
    },

    modelValue: {
      type: Date
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: true
    },
    testId: {
      type: String,
      default: 'datePickerModal'
    }
  },

  setup(props, { emit }) {
    const value = computed({
      get(): any {
        return props.modelValue ? new Date(props.modelValue) : null
      },

      set(newValue: Date) {
        emit('update:modelValue', newValue)
      }
    })

    return { value }
  }
})
