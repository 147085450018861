<template>
  <div class="name-icon">
    <div class="name-icon__text">{{ iconText }}</div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const iconText = computed(() => {
      return props.text
        .match(/\b(\w)/g)
        ?.join('')
        .substr(0, 2)
    })
    return { iconText }
  }
})
</script>

<style lang="sass" scoped>
.name-icon
  div
    border: 1px solid
    width: 2.7rem
    height: 2.7rem
    display: flex
    align-content: center
    justify-content: center
    border-radius: 100%
    font-size: 1.35rem
    line-height: 2.7rem
    background-color: #4A57F6
    color: #FFFFFF
    text-transform: uppercase
    font-weight: normal
    margin-right: 1rem
</style>
