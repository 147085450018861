
import { defineComponent, computed, ref } from 'vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import TextInput from '@/components/atom/TextInput.vue'
import { Form } from 'vee-validate'
import Header from '@/components/organisms/Header.vue'
import FormWrapper from '@/components/molecules/Form.vue'
import Datepicker from '@/components/atom/DatePicker.vue'
import SelectInput from '@/components/atom/SelectInput.vue'
import TextArea from '@/components/atom/TextArea.vue'
import SingleCheckBox from '@/components/atom/SingleCheckBox.vue'
import { projectTypes } from '@shared/'
import { useRouter, useRoute } from 'vue-router'
import authApi from '@/api/Authenticated'
import * as yup from 'yup'
import { useStore } from '@/store'
import { ActionTypes as ClientActionTypes } from '@/store/clients/actions'
import { ActionTypes as UserActionTypes } from '@/store/users/actions'
import CustomInputModel from '@/components/atom/CustomInputModel.vue'
import DatePickerModelValue from '@/components/atom/DatePickerModelValue.vue'
import { currencyToInt, inputFieldFormat } from '@/utils/currencyFunctions'
import { decimalRound, decimalValueTest } from '@/utils/commonFunctions'
import moment from 'moment'
import CustomTextArea from '@/components/atom/CustomTextArea.vue'

export default defineComponent({
  name: 'AddProject',
  components: {
    CustomButton,
    TextInput,
    Form,
    FormWrapper,
    SelectInput,
    SingleCheckBox,
    Header,
    Datepicker,
    TextArea,
    CustomInputModel,
    DatePickerModelValue,
    CustomTextArea
  },
  async setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const isAdmin = computed(() => route.path.indexOf('super-admin') > -1)
    const studioId = +route.params.studioId
    const studioDetails = computed(() => store.state.studio.studioDetails)
    const projectId = +route.params.id

    store.dispatch(ClientActionTypes.GET_CLIENTS, studioId)
    store.dispatch(UserActionTypes.GET_USERS, studioId)
    const currentValues = projectId ? await authApi.getProjectDetails(projectId) : null

    if (currentValues && currentValues.milestones.length === 0) {
      currentValues.milestones.push({ name: '', description: '', date: new Date() })
    }

    const clients = computed(() => {
      return store.state.client.clients.map((client) => {
        return { label: client.name, value: client.id }
      })
    })
    const projectManagersItems = computed(() => {
      return store.state.user.users.map((user) => {
        return { label: user.fullName, value: user.id }
      })
    })
    const feeStructureTypes = [
      {
        id: 1,
        label: 'Fixed fee',
        value: 'FixedPrice'
      },
      {
        id: 2,
        label: 'Bill per hour',
        value: 'BillPerHour'
      }
    ]

    const valuesFromApi: projectTypes.ProjectBody = {
      ...currentValues,
      totalValue: currentValues && currentValues.totalValue ? inputFieldFormat(currentValues.totalValue) : null,
      startDate: currentValues ? new Date(currentValues.startDate) : new Date(),
      invoices: currentValues
        ? currentValues.invoices.map((item: any) => {
            return { ...item, date: new Date(item.date) }
          })
        : []
    }

    const initialValues = Object.assign({}, valuesFromApi)
    const mileStonesArray = ref(initialValues.milestones ? [...initialValues.milestones] : [])
    initialValues.vatPercentage = valuesFromApi.vatPercentage
      ? valuesFromApi.vatPercentage
      : studioDetails.value
      ? studioDetails.value.VatPercentage
      : 0
    const invoiceArray = ref(
      initialValues.invoices.map((e: any) => {
        const totalAmount = e.invoiceItems
          .map((item: any) => item.unitsCount * item.costPerUnit)
          .reduce((a: any, b: any) => a + b, 0)
        return {
          ...e,
          amount: inputFieldFormat(totalAmount)
        }
      })
    )

    const staffingArray = ref(
      initialValues.staffing && initialValues.staffing.length > 0
        ? [...initialValues.staffing]
        : [{ name: '', role: '' }]
    )
    const customFields = ref(currentValues ? currentValues.projectCustomFields : [])
    const detailsCustomFields = computed(() => customFields.value.filter((e: any) => e.type === 'ProjectDetails'))
    const scopeCustomFields = computed(() => customFields.value.filter((e: any) => e.type === 'ScopeDetails'))
    const commercialCustomFields = computed(() => customFields.value.filter((e: any) => e.type === 'CommercialDetails'))

    const schema = yup.object({
      clientId: yup.number().required().label('Client'),
      name: yup.string().required().label('Name'),
      projectLeadName: yup.string().required().label('Project lead name'),
      projectLeadEmail: yup.string().required().email().label('Project lead email'),
      phase: yup.string().nullable(),
      startDate: yup.string().required(),
      ownerId: yup.number().required(),
      numberOfWeeks: yup.number().typeError('Duration must be written as a whole number').min(0).required(),
      overview: yup.string().nullable(),
      background: yup.string().nullable(),
      services: yup.string().nullable(),
      approach: yup.string().nullable(),
      restrictions: yup.string().nullable(),
      contract: yup.string().nullable(),
      additional: yup.string().nullable(),
      type: yup.string().nullable(),
      purchaseOrder: yup.string().nullable(),
      vatPercentage: yup.number().typeError('VAT/GST must be written as a whole number'),
      paymentTermsDays: yup
        .number()
        .test('is-decimal', 'Payment terms days must be written as a whole number', (val) => {
          if (val !== undefined) {
            return decimalValueTest(val.toString())
          }
          return true
        })
        .typeError('Payment terms days must be written as a whole number'),
      expensesIncluded: yup.boolean().nullable(),
      totalValue: yup.number().typeError('Total value must be written as a whole number Or as a decimal').nullable(),
      milestones: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().required().label('Name')
          })
        )
        .strict(),
      invoices: yup.array().of(
        yup.object().shape({
          date: yup.date().required().label('date')
        })
      )
    })

    const addMilestone = () => {
      mileStonesArray.value.push({ name: '', description: '', date: null })
    }

    const removeMilestone = (index: number) => {
      const filteredArr = mileStonesArray.value.filter((element, i: number) => {
        return i !== index
      })
      mileStonesArray.value = [...filteredArr]
    }

    const addStaffing = () => {
      staffingArray.value.push({ name: '', role: '' })
    }

    const removeStaffing = (index: number) => {
      const filteredArr = staffingArray.value.filter((element, i: number) => {
        return i !== index
      })
      staffingArray.value = [...filteredArr]
    }

    const addInvoice = () => {
      invoiceArray.value.push({ date: new Date(new Date().setHours(0, 0, 0, 0)), amount: 0 })
    }

    const removeInvoice = (index: number) => {
      invoiceArray.value.splice(index, 1)
    }

    const addCustomField = (type: 'ProjectDetails' | 'ScopeDetails' | 'CommercialDetails') => {
      customFields.value.push({ heading: '', description: '', type: type })
    }

    const removeCustomField = (index: number) => {
      customFields.value.splice(index, 1)
    }
    async function submit(values: projectTypes.ProjectBody) {
      values.milestones = mileStonesArray.value
        .map((e: any) => {
          return {
            ...e,
            date: moment(e.date).format('L')
          }
        })
        .filter((e) => e.name !== '')

      values.invoices = invoiceArray.value.map((e: any) => {
        return {
          ...e,
          date: moment(e.date).format('L')
        }
      })
      values.projectCustomFields = customFields.value
      values.staffing = staffingArray.value.filter((e) => e.name !== '' && e.role !== '')
      values.vatPercentage = Number(values.vatPercentage)
      values.paymentTermsDays = Number(values.paymentTermsDays)
      values.totalValue = values.totalValue ? currencyToInt(decimalRound(values.totalValue)) : null
      values.numberOfWeeks = Number(values.numberOfWeeks)
      values.startDate = moment(values.startDate).format('L')
      if (values.invoices) {
        values.invoices = values.invoices.map((item: any) => {
          return { ...item, amount: currencyToInt(+item.amount) }
        })
      }
      if (!projectId) {
        values.numberOfWeeks = Number(values.numberOfWeeks)
        values.vatPercentage = values.vatPercentage
          ? values.vatPercentage
          : studioDetails.value?.currencyCode === 'AUD'
          ? 10
          : 20
        values.startDate = moment(values.startDate).format('L')
        await authApi.addNewProject(values, studioId || undefined).then(() => {
          router.go(-1)
        })
      } else {
        await authApi.updateProject(values, +projectId).then(() => {
          router.go(-1)
        })
      }
    }

    return {
      submit,
      initialValues,
      addMilestone,
      removeMilestone,
      addInvoice,
      removeInvoice,
      schema,
      feeStructureTypes,
      clients,
      projectManagersItems,
      mileStonesArray,
      invoiceArray,
      isAdmin,
      staffingArray,
      addStaffing,
      removeStaffing,
      projectId,
      detailsCustomFields,
      addCustomField,
      removeCustomField,
      scopeCustomFields,
      commercialCustomFields
    }
  }
})
