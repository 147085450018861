
import { defineComponent, computed } from 'vue'
import { useField } from 'vee-validate'

export default defineComponent({
  name: 'CustomRadio',
  emits: ['update:modelValue'],
  props: {
    radioButtonData: {
      type: Array as () => { id: [string, number]; value: string; label: string; hintText: string }[],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    testId: {
      type: String
    }
  },
  setup(props, { emit }) {
    const { value: formValue, handleChange } = useField<string>(props.name, undefined, {
      validateOnValueUpdate: false
    })
    const value = computed({
      get(): string {
        return formValue.value
      },
      set(value: string) {
        handleChange(value)
      }
    })
    return { value }
  }
})
