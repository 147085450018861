
import { computed, ref, defineComponent } from 'vue'

export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholderText: String,
    name: {
      type: String,
      required: false
    },
    label: {
      type: String
    },
    hint: String,
    disabled: Boolean,
    modelValue: {
      type: [String, Number],
      required: true
    },
    inputViewType: {
      type: String,
      default: 'default'
    },
    required: {
      type: Boolean,
      default: false
    },
    testId: {
      type: String
    }
  },
  setup(props, { emit }) {
    const inputValue = computed({
      get(): any {
        return props.modelValue ? props.modelValue : null
      },
      set(value: number) {
        emit('update:modelValue', value)
      }
    })
    return {
      inputValue
    }
  }
})
