
import { defineComponent, ref, computed } from 'vue'
import { Form } from 'vee-validate'
import Header from '@/components/organisms/Header.vue'
import FormWrapper from '@/components/molecules/Form.vue'
import SelectInput from '@/components/atom/SelectInput.vue'
import TextInput from '@/components/atom/TextInput.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import authApi from '@/api/Authenticated'
import { useStore } from '@/store'
import { useRoute, useRouter } from 'vue-router'
import { ActionTypes as projectActions } from '@/store/projects/actions'
import { ActionTypes as UserActionTypes } from '@/store/users/actions'
import { ActionTypes as studioActions } from '@/store/studio/actions'
import * as yup from 'yup'
import CustomMultiSelectInput from '@/components/atom/CustomMultiSelectInput.vue'
import { projectTypes } from '@shared/'
import { inputFieldFormat, currencyToInt } from '@/utils/currencyFunctions'

export default defineComponent({
  components: {
    Form,
    Header,
    FormWrapper,
    SelectInput,
    TextInput,
    CustomButton,
    CustomMultiSelectInput
  },
  async setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const projectId = +route.params.id
    const projectServiceId = route.query.type ? +route.query.type : 0
    const serviceId = +route.params.roleId
    const totalSelectedTeammembers = ref<any>([])
    const rateValue = ref()

    const schema = yup.object().shape({
      serviceId: yup.string().label('Role').required(),
      rate: yup.number().typeError('Hourly Rate is required and must be number').min(0).label('Hourly Rate').required(),
      teammembers: yup.array()
    })

    await store.dispatch(UserActionTypes.GET_USERS)
    await store.dispatch(studioActions.GET_SUBCONTRACTORS)
    await store.dispatch(projectActions.GET_PROJECT_TEAM, projectId)

    // Fetch subcontractors list
    const subcontractorsList = computed(() => {
      return store.state.studio.subContractors.map((subcontractor) => {
        if (subcontractor) {
          return { name: subcontractor.name, value: subcontractor.id + 'sb' }
        }
      })
    })
    // Fetch users list
    const userList = computed(() => {
      return store.state.user.users.map((user) => {
        if (user) {
          return { name: user.fullName, value: user.id + 'u' }
        }
      })
    })

    // Combine the user and subcontractor list
    const teammemberList = [...userList.value, ...subcontractorsList.value]

    // Users selected for a particular role
    const TotalselectedUserList = async () => {
      await store.state.project.projectTeam?.ProjectService.map((team: any) => {
        if (team.serviceId === serviceId) {
          rateValue.value = team.rate
          if (team.user) {
            team.user.map((user: any) => totalSelectedTeammembers.value.push(user.id + 'u'))
          }
          if (team.subcontractor) {
            team.subcontractor.map((subcontractor: any) => totalSelectedTeammembers.value.push(subcontractor.id + 'sb'))
          }
        }
      }).filter((x: any) => x !== undefined)
    }

    await TotalselectedUserList()

    const initialValues: any = {
      serviceId: serviceId,
      teammembers: totalSelectedTeammembers.value ? totalSelectedTeammembers.value : '',
      rate: inputFieldFormat(rateValue.value)
    }
    const projectServiceArray = ref([
      {
        teammembersId: totalSelectedTeammembers.value ? totalSelectedTeammembers.value : '',
        user: [{ id: 0 }],
        subcontractor: [{ id: 0 }]
      }
    ])
    // Fetch all the roles
    const getRoles = await authApi.getProjectRoles()

    // Filter roles according to project
    const roles = computed(() => {
      return getRoles
        .filter((array) => array.id === serviceId)
        .map((service: any) => {
          return { label: service.name, value: service.id }
        })
    })

    function checkTeamPattern(selectedUsers: any) {
      return selectedUsers
        .map((item: any) => {
          if (item) {
            if (item.indexOf('u') > -1) {
              projectServiceArray.value[0].user.push({ id: +item.replace('u', '') })
            } else {
              projectServiceArray.value[0].subcontractor.push({ id: +item.replace('sb', '') })
            }
          }
        })
        .filter((x: any) => x !== undefined)
    }

    // Save the new project role
    async function submit(values: projectTypes.projectService & { teammembers: any }) {
      checkTeamPattern(projectServiceArray.value[0].teammembersId)
      values.id = +projectServiceId
      values.rate = currencyToInt(+values.rate)
      values.serviceId = +serviceId
      values.user = projectServiceArray.value[0].user.filter((value) => value.id !== 0)
      values.subcontractor = projectServiceArray.value[0].subcontractor.filter((value) => value.id !== 0)
      const res = await authApi.updateProjectService(+projectId, [values])
      if (res) {
        router.go(-1)
      }
    }
    return {
      roles,
      submit,
      schema,
      teammemberList,
      totalSelectedTeammembers,
      rateValue,
      initialValues,
      projectServiceArray
    }
  }
})
