<template>
  <label>{{ label }}</label>
  <Multiselect
    v-model="inputValue"
    mode="tags"
    :placeholder="placeholderText"
    :name="name"
    :clearOnSearch="true"
    :searchable="true"
    :options="items"
    :value="selectedList"
    @select="(event) => $emit('update:modelValue', inputValue)"
    @deselect="(event) => $emit('update:modelValue', inputValue)"
    @close="(event) => $emit('update:modelValue', inputValue)"
    class="default-view"
  >
    <template v-slot:singlelabel="{ value }">
      <div class="multiselect-single-label">
        <NameIcon :text="value.name" />
        <span>{{ value.name }}</span>
      </div>
    </template>
    <template v-slot:option="{ option }">
      <NameIcon :text="option.name" />
      <span>{{ option.name }}</span>
    </template>
    <template v-slot:tag="{ option, disabled, handleTagRemove }">
      <div class="multiselect-tag is-user">
        <NameIcon :text="option.name" />
        <span>{{ option.name }}</span>
        <i v-if="!disabled" @click.prevent @mousedown.prevent.stop="handleTagRemove(option, $event)" />
      </div>
    </template>
  </Multiselect>
  <span v-if="errorMessage" class="input-error">{{ errorMessage }}</span>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import Multiselect from '@vueform/multiselect'
import { useField } from 'vee-validate'
import NameIcon from '@/components/atom/NameIcon.vue'
export default defineComponent({
  name: 'MultiSelectInput',
  emits: ['update:modelValue'],
  props: {
    label: {
      type: String
    },
    placeholderText: {
      type: String
    },
    name: {
      type: String,
      required: true
    },
    items: {
      type: Array as () => { value: string; label: string }[],
      required: true
    },
    selectedList: {
      type: Array
    },
    modelValue: {
      type: [String, Number, Object],
      required: true
    }
  },
  components: {
    Multiselect,
    NameIcon
  },
  setup(props) {
    // eslint-disable-next-line prettier/prettier
    const { value: formValue, handleChange, errorMessage } = useField<any>(props.name, undefined, {
      validateOnValueUpdate: false
    })

    const inputValue = props.modelValue ? ref(props.modelValue) : props.selectedList
    const value = computed({
      get(): any {
        return props.selectedList ? props.selectedList : formValue.value
      },
      set(value: any) {
        handleChange(value)
      }
    })
    return { value, handleChange, errorMessage, formValue, inputValue }
  }
})
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped lang="sass">
.default-view
  background-color: #FFFFFF
  padding: 0.5rem 0.5rem
  margin: 10px 0px 10px 0px
  border: solid 1px #CFD2D7
.multiselect-tag.is-user
  padding: 0.3rem 0.5rem 0.3rem 0rem
  border: solid 1px #CFD2D7
  background: transparent
  margin: 3px 3px 8px
  color: black
  font-weight: 100
  font-size: 17px
  span
    width: 100%
    overflow: hidden
  & img
    width: 18px
    border-radius: 50%
    height: 18px
    margin-right: 8px
    border: 2px solid #ffffffbf
  & i:before
    color: black
    border-radius: 50%
    cursor: pointer
    text-align: right
    position: relative
    top: -1px
    right: -3px
    background: #CFD2D7
    font-size: 1.3rem
    width: 1.5rem
    height: 1.5rem
    display: flex
    justify-content: center
    align-items: center
    align-self: flex-end
.multiselect-tag i
  cursor: text !important
</style>
<style lang="sass">
.multiselect-tags
  width: 100%
  padding-right: 12px
  span
    width: 100%
.default-view .multiselect-input
  border: none !important
.multiselect-clear, .multiselect-caret
  display: none
</style>
