
import { defineComponent, ref, computed } from 'vue'
import { Form } from 'vee-validate'
import Header from '@/components/organisms/Header.vue'
import FormWrapper from '@/components/molecules/Form.vue'
import SelectInputModal from '@/components/atom/SelectInputModal.vue'
import PrepoulateInputModel from '@/components/atom/PrepoulateInputModel.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import authApi from '@/api/Authenticated'
import { useStore } from '@/store'
import { useRoute, useRouter } from 'vue-router'
import { ActionTypes as projectActions } from '@/store/projects/actions'
import { ActionTypes as UserActionTypes } from '@/store/users/actions'
import { ActionTypes as studioActions } from '@/store/studio/actions'
import * as yup from 'yup'
import CustomMultiSelectInput from '@/components/atom/CustomMultiSelectInput.vue'
import { currencyToInt, inputFieldFormat } from '@/utils/currencyFunctions'

export default defineComponent({
  components: {
    Form,
    Header,
    FormWrapper,
    SelectInputModal,
    PrepoulateInputModel,
    CustomButton,
    CustomMultiSelectInput
  },
  async setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const projectId = +route.params.id
    const projectRoleArray = ref([
      {
        serviceId: 0,
        teammembersId: 0,
        rate: 0,
        user: [],
        subcontractor: []
      }
    ])
    const schema = yup.object().shape({
      role: yup.string().required()
    })

    await store.dispatch(UserActionTypes.GET_USERS)
    await store.dispatch(studioActions.GET_SUBCONTRACTORS)
    await store.dispatch(projectActions.GET_PROJECT_TEAM, projectId)

    // Fetch subcontractors list
    const subcontractorsList = computed(() => {
      return store.state.studio.subContractors.map((subcontractor) => {
        if (subcontractor) {
          return { name: subcontractor.name, value: { subcontractorId: subcontractor.id } }
        }
      })
    })
    // Fetch users list
    const userList = computed(() => {
      return store.state.user.users.map((user) => {
        if (user) {
          return { name: user.fullName, value: { userId: user.id } }
        }
      })
    })

    // Combine the user and subcontractor list
    const teammemberList = [...userList.value, ...subcontractorsList.value]

    // Fetch all the roles
    const getRoles = await authApi.getProjectRoles()

    // Filter roles according to project
    const roles = computed(() => {
      return getRoles
        .filter((array) =>
          store.state.project.projectTeam?.ProjectService.every((filter: any) => !(filter.serviceId === array.id))
        )
        .map((service: any) => {
          return { label: service.name, value: service.id }
        })
    })

    // Add new project role
    const addProjectRole = () => {
      projectRoleArray.value.push({
        serviceId: 0,
        teammembersId: 0,
        rate: 0,
        user: [],
        subcontractor: []
      })
    }
    // Remove project role
    const removeProjectRole = (index: number, serviceId: number) => {
      const filteredArr = projectRoleArray.value.filter((element, i: number) => {
        return i !== index
      })
      roles.value.map((role: any) => {
        if (role.value === serviceId) {
          role.disabled = false
        }
      })
      projectRoleArray.value = [...filteredArr]
    }

    // Save the new project role
    async function submit() {
      const projectService = projectRoleArray.value
        .map((service: any) => {
          if (service.serviceId && service.teammembersId) {
            service.teammembersId.map((teammember: any) => {
              if (teammember.userId) {
                service.user.push({ id: teammember.userId })
              } else if (teammember.subcontractorId) {
                service.subcontractor.push({ id: teammember.subcontractorId })
              }
            })
            return {
              serviceId: service.serviceId,
              rate: currencyToInt(+service.rate),
              user: service.user,
              subcontractor: service.subcontractor,
              estimatedHours: 0
            }
          }
        })
        .filter((x: any) => x !== undefined)
      const res = await authApi.updateProjectService(+projectId, projectService as Array<any>)
      if (res) {
        router.go(-1)
      }
    }
    const onChange = (event: any, index: number) => {
      const serviceId = Number(event.target.value)
      getRoles.map((service: any) => {
        if (service.id === serviceId) {
          projectRoleArray.value[index].rate = inputFieldFormat(service.rate)
        }
      })
      roles.value.map((data: any) => {
        data.disabled = false
      })
      projectRoleArray.value.map((projectService: any) => {
        roles.value.map((role: any) => {
          if (role.value === projectService.serviceId) {
            role.disabled = true
          }
        })
      })
    }
    return {
      roles,
      projectRoleArray,
      addProjectRole,
      removeProjectRole,
      submit,
      onChange,
      schema,
      teammemberList
    }
  }
})
