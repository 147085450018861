
import { ref, defineComponent, computed } from 'vue'
import { Form } from 'vee-validate'
import FormWrapper from '@/components/molecules/Form.vue'
import Radio from '@/components/molecules/Radio.vue'
import TextInput from '@/components/atom/TextInput.vue'
import Header from '@/components/organisms/Header.vue'
import CustomRadio from '@/components/atom/CustomRadio.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import { userTypes } from '@shared/'
import authApi from '@/api/Authenticated'
import { useRouter, useRoute } from 'vue-router'
import * as yup from 'yup'
import { useStore } from '@/store'
import { ActionTypes as AuthActionTypes } from '@/store/auth/actions'

export default defineComponent({
  components: {
    Form,
    FormWrapper,
    TextInput,
    CustomRadio,
    Radio,
    CustomButton,
    Header
  },
  async setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    const radioData = [
      {
        id: 1,
        value: 'ADMIN',
        label: 'Admin',
        hintText: 'Studio leadership team member, able to see all data and make full range of changes to Studiospace.'
      },
      {
        id: 2,
        value: 'ACC_MAN',
        label: 'Account manager',
        hintText: 'Client service leader, able to see and edit all data relating to their client companies.'
      },
      {
        id: 3,
        value: 'PRO_MAN',
        label: 'Project manager',
        hintText: 'Owner of the project, able to see and edit all data relating to a specific project.'
      },
      {
        id: 4,
        value: 'MEMBER',
        label: 'Team member',
        hintText: 'Working on range of projects, can be informed, but not able to access Studiospace functions.'
      }
    ]

    const schema = yup.object({
      email: yup.string().required().email().label('Email'),
      fullName: yup.string().required().label('Full name'),
      role: yup.string().required()
    })
    const isEdit = ref(route.params.id)

    const addInitialValues: userTypes.UserBody = {
      email: '',
      fullName: '',
      role: 'ADMIN'
    }

    const initialValues = isEdit.value
      ? ref<userTypes.UserResponse>(await authApi.getUser(+route.params.id))
      : ref(addInitialValues)
    const checkUserEditOption = computed(() => {
      return store.state.auth.tokenContent?.userId !== +route.params.id
    })
    async function submit(values: userTypes.UserBody) {
      const res = isEdit.value ? await authApi.updateUser(values, +route.params.id) : await authApi.addNewUser(values)
      if (res) {
        router.push({ name: 'Team' })
      }
      if (values.role === 'ADMIN' || initialValues.value.role === 'ADMIN') {
        store.dispatch(AuthActionTypes.REFRESH_TOKEN)
      }
    }
    return { initialValues, schema, submit, radioData, isEdit, checkUserEditOption }
  }
})
