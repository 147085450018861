<template>
  <div class="form-group">
    <span v-if="label" class="input-label">{{ label }}</span>
    <div class="input-field">
      <input
        :name="name"
        v-model="inputValue"
        :id="name"
        :type="type"
        :placeholder="placeholderText"
        :disabled="disabled"
        :required="required"
        @input="(event) => $emit('update:modelValue', inputValue)"
        :class="inputViewType === 'default' ? 'default-input' : ''"
        :data-testid="testId"
      />
      <label class="label" for="name" v-if="inputValue && inputViewType !== 'small'" data-testId="verifyLabel">
        {{ placeholderText }}
        <div class="verified"></div>
      </label>
      <!-- <span v-if="errorMessage" class="input-error">{{ errorMessage }}</span> -->
    </div>
    <small class="hint-text" v-if="hint">{{ hint }}</small>
  </div>
</template>

<script lang="ts">
import { computed, ref, defineComponent } from 'vue'

export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholderText: String,
    name: {
      type: String,
      required: false
    },
    label: {
      type: String
    },
    hint: String,
    disabled: Boolean,
    modelValue: {
      type: [String, Number],
      required: true
    },
    inputViewType: {
      type: String,
      default: 'default'
    },
    required: {
      type: Boolean,
      default: false
    },
    testId: {
      type: String
    }
  },
  setup(props, { emit }) {
    const inputValue = computed({
      get(): any {
        return props.modelValue ? props.modelValue : null
      },
      set(value: number) {
        emit('update:modelValue', value)
      }
    })
    return {
      inputValue
    }
  }
})
</script>

<style lang="sass" scoped>
.form-group
  position: relative
  width: 100%
  display: flex
  flex-direction: column
.input-field
  position: relative
input
  font-size: 1rem
  font-family: TT-Norms-Pro
  padding: 1rem 0.5rem
  display: block
  width: 100%
  border: solid 1px map-get($greyShades, 'greyShade20')
  border-radius: 2px
  caret-color: $primary
  color: $primary
  transition: 0.2s ease all
  -moz-transition: 0.2s ease all
  -webkit-transition: 0.2s ease all
  text-overflow: ellipsis
  &:focus
    outline: none
    border: solid 1px map-get($greyShades, 'greyShade70')
    &:disabled
      border: solid 1px map-get($greyShades, 'greyShade20')
    &:disabled ~ label
      color: map-get($greyShades, 'greyShade50')
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0
    -moz-appearance: textfield
label
  color: $primary
  font-size: 14px
  font-family: TT-Norms-Pro
  font-weight: normal
  position: absolute
  pointer-events: none
  right: 1rem
  line-height: 1rem
  top: 20px
  display: flex
  align-items: center
  transition: 0.2s ease all
  -moz-transition: 0.2s ease all
  -webkit-transition: 0.2s ease all
.hint-text
  color: $primary
  font-family: TT-Norms-Pro
  font-size: 12px
.verified
  margin-left: 0.5rem
  height: 1rem
  width: 1rem
  background-image: url('~@/assets/icons/tick-green.svg')
  background-size: 90%
  background-position: center
  background-color: transparent
  background-repeat: no-repeat
.input-label
  text-align: left
  margin-bottom: 4px
  // margin-top: 1rem
.default-input
  padding-right: 10rem
</style>
