
import { defineComponent, computed, ref } from 'vue'
import Multiselect from '@vueform/multiselect'
import { useField } from 'vee-validate'
import NameIcon from '@/components/atom/NameIcon.vue'
export default defineComponent({
  name: 'MultiSelectInput',
  emits: ['update:modelValue'],
  props: {
    label: {
      type: String
    },
    placeholderText: {
      type: String
    },
    name: {
      type: String,
      required: true
    },
    items: {
      type: Array as () => { value: string; label: string }[],
      required: true
    },
    selectedList: {
      type: Array
    },
    modelValue: {
      type: [String, Number, Object],
      required: true
    }
  },
  components: {
    Multiselect,
    NameIcon
  },
  setup(props) {
    // eslint-disable-next-line prettier/prettier
    const { value: formValue, handleChange, errorMessage } = useField<any>(props.name, undefined, {
      validateOnValueUpdate: false
    })

    const inputValue = props.modelValue ? ref(props.modelValue) : props.selectedList
    const value = computed({
      get(): any {
        return props.selectedList ? props.selectedList : formValue.value
      },
      set(value: any) {
        handleChange(value)
      }
    })
    return { value, handleChange, errorMessage, formValue, inputValue }
  }
})
